import React from "react"
import { css } from "@emotion/react" 
// import { useStaticQuery, graphql } from "gatsby"

const css_full = css`
  padding: 14px 0 32px 0;
  margin: 0 0 24px 0;
  background: var(--primary);
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
`;

const css_base = css`
  .gatsby-image-wrapper{
      overflow: visible !important;
      display: block !important;
      margin: 0 auto;
      /* background: white; */
  }  
  .nav-links{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    gap: 24px 24px;
    margin-top: 25px;
    }
    .nav-links > div{
      margin: 0 auto;
    }
  img{
    /* max-width: 600px; */
    /* border-radius: 50%; */
    /* box-shadow: 2px 6px 6px rgba(0,0,0,0.3); */
  }
  a{
    /* display: inline; */
    text-transform: uppercase;
    margin: 0 auto;
    text-align:center;
    /* font-weight: 600; */
    color: white;
    text-decoration: none;
    position: relative;
  }
  a:after{
    content: "";
    position: absolute;
    bottom:-2px;
    left:50%;
    border-bottom: 3px solid var(--secondary);
    width: 0%;
    /* height: 2px; */
    transition: all 0.2s;
  }
  a:hover:after{
    left: 0;
    width: 100%;
  }

  @media screen and (min-width: 769px){
    .nav-links{
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }  
`

export default function Navbar(props) {

  return (
    <div className="full-bleed" css={css_full} style={{
      boxShadow: 'inset 0 0 0 2000px rgba(var(--primaryalt), 0.75)',
      backgroundSize: 'cover',
      backgroundPosition: 'center 20%',
    }}>
      <div className="wrapper">
        <div css={css_base}>{props.children}</div>
      </div>
    </div>
  )
}
