import React from "react"
import { Global, css } from "@emotion/react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Navbar from "./bb/navbar"
import Footer from "./bb/footer"
import {Link} from "gatsby"

const globalCSS = css`
  :root{
    --primary: #3e3e3e; /* dark grey */
    --primaryalt: 62, 62, 62; /* the same in RGB */
    --secondary: #9c27b0 ; /* purple */
    --secondaryalt: 156, 39, 176; /* RGB */
    
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr min(90ch, calc(100% - 48px)) 1fr;
    grid-column-gap: 24px;
  }
  .wrapper > * {
    grid-column: 2;
  }
  .full-bleed {
    width: 100%;
    grid-column: 1 / 4;
  }
  p a{
    color: var(--primary);
    font-weight: bold;
    transition: all 0.2s;
    position: relative;
  } 
  p a:hover{
    color: var(--secondary);
  }
  p a:after{
    content: "";
    position: absolute;
    bottom:-2px;
    left:50%;
    border-bottom: 3px solid var(--primary);
    width: 0%;
    /* height: 2px; */
    transition: all 0.2s;
  }
  p a:hover:after{
    left: 0;
    width: 100%;
  }
  .logo-image{
    /* max-width:90ch; */
    /* margin: 0 auto; */
    background: #a6a6a6;
  }
`

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
  query {
    logo: file(relativePath: {eq: "images/rae-fox-logo.png"}) {
      childImageSharp {
        fluid(maxWidth: 600){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (
    <div className="wrapper">
      <Global styles={globalCSS} />
      <div className="logo-image full-bleed">
        <div className="wrapper">
            <Img fluid={data.logo.childImageSharp.fluid} />
        </div>
      </div>
      <Navbar>
        <div className="nav-links">
          <div><Link to="/">Home</Link></div>
          <div><Link to="/books/">Books</Link></div>
          <div><Link to="/#about">About Rae Fox</Link></div>
          <div><Link to="/#connect">Connect</Link></div>
        </div>
      </Navbar>
      {children}
      <Footer>
        <div className="nav-links">
          <div><Link to="/">Home</Link></div>
          <div><Link to="/books/">Books</Link></div>
          <div><Link to="/#about">About Rae Fox</Link></div>
          <div><Link to="/#connect">Connect</Link></div>
        </div>
        <p>&copy; {new Date().getFullYear()} Rae Fox, Author All Rights Reserved.</p>
        <p>Designed with <span role="img" aria-label="heart">💜</span> by <a href="mailto:pmelville321@gmail.com">Patrick Melville</a></p>
      </Footer>
    </div>
  )
}
